<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Partner/List</h4>
          <div>
            <router-link :to="can('partner-create') ? {name:'appPartnerCreate'} : '#'">
                        <span :title="can('partner-create') ? 'Create' : 'Create Forbidden'"
                              class="glow d-flex align-items-center">
                            <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>

        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Show Per Page</label>
                <VueMultiselect v-model="selectedPagination" class="" :options="pagination" :close-on-select="true"
                                label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Role</label>
                <VueMultiselect v-model="selectedRole" class="" :options="roleList" :close-on-select="true" label="name"
                                track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>State</label>
                <VueMultiselect v-model="selectedState" class="" :options="stateList" :close-on-select="true"
                                label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Status</label>
                <VueMultiselect v-model="selectedStatus" class="" :options="status" :close-on-select="true"
                                placeholder="Select status" label="name" track-by="value" :show-labels="false"
                                :allow-empty="false"/>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9  col-lg-9">
                <input v-model="getPartnersParams.where_has_user_query" class="form-control search-admin-input-element"
                       type="text" placeholder="Search a partner by name or email or phone">
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyPartnersFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'20%'}">NAME</th>
                    <th class="position-relative" :style="{width:'18%'}">ADDRESS</th>
                    <th class="position-relative" :style="{width:'12%'}">Role</th>
                    <th class="position-relative" :style="{width:'20%'}">EMAIL</th>
                    <th class="position-relative" :style="{width:'15%'}">PHONE</th>
                    <th class="position-relative" :style="{width:'10%'}">STATUS</th>
                    <th :style="{width:'5%'}">ACTIONS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(partner) in partners" :key="partner.id">
                    <td>
                      <span v-if="partner?.user?.full_name" class="partner-name">{{ partner?.user?.full_name }}</span>
                    </td>
                    <td>
                      <span v-if="partner.address">{{ partner.address.street }}, {{
                          partner.address.suburb
                        }} {{ partner.address.state }} {{
                          partner.address.post_code
                        }}, {{ partner.address.country }}</span>
                    </td>
                    <td>
                      <span v-if="partner?.user?.role">{{ partner.user.role.name }}</span>
                    </td>
                    <td>
                      <span v-if="partner?.user?.email">{{ partner.user.email }}</span>
                      <span v-else>Not Provided</span>
                    </td>
                    <td>
                      <span v-if="partner?.user?.phone_number">{{ partner.user.phone_number }}</span>
                      <span v-else>Not Provided</span>
                    </td>
                    <td>
                      <span v-if="partner?.status" class="badge"
                            :class="partner?.status === 'Active' ? 'badge-light-success' : 'badge-light-danger'">{{
                          partner.status
                        }}</span>
                    </td>
                    <td>
                      <div class="d-flex align-items-center justify-content-between">
                        <router-link :title="can('partner-view') ? 'View' : 'View Unauthenticated'"
                                     :to="can('partner-view') ? {name:'appPartnerView',params:{id:partner.id}} : '#'"
                                     class="text-decoration-none view-edit-delete-icon" style="height: 18px">
                          <i class='bx bx-show'></i>
                        </router-link>
                        <button :title="can('partner-delete') ? 'Delete' : 'Delete Unauthenticated'"
                                :disabled="!can('partner-delete')" @click="this.modelIdForDelete = partner.id"
                                class="btn text-decoration-none cursor-pointer view-edit-delete-icon px-0"
                                data-toggle="modal" data-target="#adminDeleteAlertModal">
                          <i class='bx bx-x'></i>
                        </button>
                        <div class="dropdown py-0 px-0 view-edit-delete-icon" :style="{height:'18px'}">
                                                    <span type="button" class="px-0 py-0" data-toggle="dropdown"
                                                          aria-haspopup="true" aria-expanded="false">
                                                        <i class='bx bx-dots-vertical-rounded h-100'></i>
                                                    </span>
                          <div class="dropdown-menu">

                            <button :disabled="!can('partner-update')" class="dropdown-item"
                                    @click="openRestPasswordModal(partner)">Reset Password
                            </button>

                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks"
                                @getClickedPage="applyPartnersFilter"/>
              </div>
            </div>
          </div>
        </div>
        <div class="" data-toggle="modal" data-target="#restPasswordModal"></div>
        <partner-delete-alert-modal :model-id="modelIdForDelete" model-name="partner" modal-name="adminDeleteAlertModal"
                                    @confirmModelDeletion="confirmModelDeletion"/>
        <ResetPasswordModal :userEmailOrPhone="resetPasswordUserEmailOrPhone"/>
      </section>
    </template>
  </AppLayout>
</template>

<script>
//component
import AppLayout from '@/layouts/backEnd/AppLayout';
import PartnerDeleteAlertModal from '@/components/backEnd/modal/DeleteAlertModal';
import ListPagination from '@/components/backEnd/pagination/ListPagination';

// mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

// core package
import {
  mapActions,
  mapGetters
} from "vuex";

// package
import VueMultiselect from 'vue-multiselect';
import ResetPasswordModal from '@/components/backEnd/modal/ResetPasswordModal';

export default {
  name: "PartnerList",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    PartnerDeleteAlertModal,
    ListPagination,
    VueMultiselect,
    ResetPasswordModal
  },
  data() {
    return {
      resetPasswordUserEmailOrPhone: '',
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      selectedRole: {
        value: '',
        name: 'Any'
      },
      selectedState: {
        value: '',
        name: 'Any'
      },
      selectedStatus: {
        value: '',
        name: 'Any'
      },
      getPartnersParams: {
        where_has_user_query: '',
        where_has_address_state: '',
        where_has_user_role_id: '',
        status: '',
        with_relation: ['user', 'user.role', 'address'],
        order_by_id: 'DESC',
        paginate: 1,
        pagination: '',
        page: ''
      },
      modelIdForDelete: '',
      getSettingsParams: {
        type: ['default'],
        key: ['pagination', 'default_state'],
      },
      getRolesParams: {
        type: 8
      },
    };
  },
  watch: {
    selectedPagination(selectedPagination) {
      this.getPartnersParams.pagination = selectedPagination.value;
    },
    selectedState(selectedState) {
      this.getPartnersParams.where_has_address_state = selectedState.value;
    },
    selectedRole(selectedRole) {
      this.getPartnersParams.where_has_user_role_id = selectedRole.value;
    },
    selectedStatus(selectedStatus) {
      this.getPartnersParams.status = selectedStatus.value;
    },
  },
  computed: {
    ...mapGetters({
      partners: 'appPartners/partners',
      paginateLinks: 'appPartners/paginateLinks',
    }),
    status() {
      return [{
        value: '',
        name: 'Any'
      },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ];
    },
    pagination() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }];
    },
    stateList() {
      return [{
        value: '',
        name: 'Any'
      }, ...this.$store.getters['appSettings/settingDefaultState'].value];
    },
    roleList() {
      let activeRoles = this.$store.getters['appRoles/roles'].map((roleObj) => {
        let roleId = roleObj.id;
        let roleName = roleObj.name;

        return {
          value: roleId,
          name: roleName
        };
      });

      return [{
        value: '',
        name: 'None'
      }, ...activeRoles];
    },
  },
  methods: {
    ...mapActions({
      resetRoles: 'appRoles/resetRoles',
      resetPartners: 'appPartners/resetPartners',

      getSettings: 'appSettings/getSettings',
      getRoles: 'appRoles/getRoles',
      getPartners: 'appPartners/getPartners',
      deletePartnerOnList: 'appPartners/deletePartnerOnList'
    }),

    openRestPasswordModal(partner) {
      this.resetPasswordUserEmailOrPhone = "";
      if (partner?.user?.email) {
        this.resetPasswordUserEmailOrPhone = partner.user.email;
        document.querySelector('[data-target="#restPasswordModal"]').click();
        return;
      }
      if (partner?.user?.phone_number) {
        this.resetPasswordUserEmailOrPhone = partner.user.phone_number;
        document.querySelector('[data-target="#restPasswordModal"]').click();
        return;
      }
      console.log(partner, "customer ");
    },

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getPartnerTypeRoleList() {
      await this.getRoles(this.getRolesParams);
    },

    async getPartnerList() {
      await this.getPartners(this.getPartnersParams).then(async (response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async applyPartnersFilter(pageNumber) {
      this.loader(true);
      this.getPartnersParams.page = pageNumber;
      await this.getPartnerList();
      this.loader(false);
    },

    async confirmModelDeletion(confirmModelDeletion) {
      await this.deleteSinglePartnerOnLIst(confirmModelDeletion.modelId);
    },

    async deleteSinglePartnerOnLIst(id) {
      this.deletePartnerOnList(id).then(async (response) => {
        this.showToastMessage(response);
      });
    }
  },

  async mounted() {
    this.loader(true);
    await this.getSettingList();
    await this.getPartnerTypeRoleList();
    await this.getPartnerList();
    this.loader(false);
  },

  async beforeUnmount() {
    await this.resetRoles();
    await this.resetPartners();
  },
}
</script>

<style scoped>
.partner-name {
  color: #5A8DEE;
}

.view-edit-delete-icon {
  color: #B3C0CE;
  transition: all 0.2s;
}

.view-edit-delete-icon:hover {
  color: #5A8DEE;
}

.search-admin-input-element {
  min-height: 41px !important;
  padding: 8px 40px 8px 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
</style>
